import React from "react";
import Link from "@/components/link";
import { cn } from "@/helpers/classNames";
import { useLocales } from "@/hooks/useLocales";

const LocaleSwitcherLink: React.FC<LocaleLink> = ({
  url,
  name,
  abbr,
  isCurrent,
}) => {
  const baseClassName = "inline-block rounded-sm p-1.5";

  if (isCurrent) {
    return (
      <span title={name} className={cn(baseClassName, "bg-white shadow")}>
        {abbr}
      </span>
    );
  }

  if (!url) {
    return (
      <span
        title={name}
        className={cn(baseClassName, "opacity-50 cursor-not-allowed")}
      >
        {abbr}
      </span>
    );
  }

  return (
    <Link className={baseClassName} to={url} title={name}>
      {abbr}
    </Link>
  );
};

const LocaleSwitcher: React.FC<{ className?: string }> = ({
  className = undefined,
}) => {
  const links = useLocales();

  return (
    <nav className={className}>
      <ul className="flex p-0.5 rounded-[0.1875rem] text-sm tracking-tight leading-none bg-theme-700/25">
        {links.map(({ locale, ...link }) => (
          <li key={locale}>
            <LocaleSwitcherLink locale={locale} {...link} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default LocaleSwitcher;
