import React from "react";
import ThemeContext from "@/contexts/theme";
import Hamburger from "@/layouts/partials/hamburger";

const MegaMenuButton: React.FC<{ id: string }> = ({ id }) => (
  <ThemeContext.Consumer>
    {({ menuOpen, setMenuOpen }) => (
      <Hamburger
        label={`label-${id}`}
        aria-haspopup="menu"
        aria-controls={`megamenu-${id}`}
        aria-expanded={menuOpen}
        active={menuOpen}
        onToggle={(active) => setMenuOpen(active)}
        className="size-10 sm:size-auto"
      />
    )}
  </ThemeContext.Consumer>
);

export default MegaMenuButton;
