import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useTranslations } from "@/contexts/translations";
import { locales } from "@/locales/locales";

export const useLocales = () => {
  const { defaultLocale, locale } = useIntl();
  const { translations } = useTranslations();

  return useMemo<LocaleLink[]>(
    () =>
      locales.map((lang) => ({
        ...lang,
        isDefault: lang.locale === defaultLocale,
        isCurrent: lang.locale === locale,
        url: translations[lang.locale] ?? lang.url,
      })),
    [defaultLocale, locale, translations],
  );
};
