import React, { useMemo } from "react";
import dayjs from "dayjs";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "react-intl";
import Container from "@/components/container";
import DocumentLink from "@/components/document-link";
import Link from "@/components/link";
import ThemeContext from "@/contexts/theme";
import { cn } from "@/helpers/classNames";
import Grid from "@/layouts/partials/grid";
import LocaleSwitcher from "@/layouts/partials/locale-switcher";
import Logo from "@/layouts/partials/logo";
import LogoWordmark from "@/layouts/partials/logo-wordmark";
import MegaMenu from "@/layouts/partials/mega-menu";
import MegaMenuButton from "@/layouts/partials/mega-menu-button";
import ThemeSwitcher from "@/layouts/partials/theme-switcher";
import { slug } from "@/utils/slug";

const Header: React.FC = () => {
  const { settings, tenders } = useStaticQuery<Queries.HeaderQuery>(graphql`
    query Header {
      settings: sanitySettings {
        primaryNavigation {
          ...InternationalizedDocumentReferenceList
        }
        tendersPage {
          locale: _key
          value {
            reference {
              id
            }
          }
        }
        activeTendersIndicator
      }
      tenders: allSanityTender(
        filter: { isPublished: { eq: true } }
        limit: 1
        sort: { deadlineDate: DESC }
      ) {
        edges {
          node {
            deadlineDate
          }
        }
      }
    }
  `);

  const { formatMessage, locale } = useIntl();

  const homeLink = useMemo(() => slug("home", locale), [locale]);

  const links = useMemo(
    () =>
      settings?.primaryNavigation?.find((item) => item?.locale === locale)
        ?.value,
    [settings, locale],
  );

  const tendersPageId = useMemo(
    () =>
      settings?.activeTendersIndicator &&
      tenders?.edges?.some(
        ({ node }) => node?.deadlineDate && dayjs(node?.deadlineDate).isAfter(),
      ) &&
      settings?.tendersPage?.find((item) => item?.locale === locale)?.value
        ?.reference?.id,
    [locale, tenders, settings?.tendersPage, settings?.activeTendersIndicator],
  );

  return (
    <ThemeContext.Consumer>
      {({ headerClassName }) => (
        <header
          id="top"
          className={cn(headerClassName, "transition-colors select-none")}
        >
          <Container className="flex items-center gap-x-base py-md lg:py-base">
            <div className="flex items-center flex-1 gap-gutter lg:gap-0">
              <div className="relative size-16 xs:size-20 lg:size-0 lg:static">
                <span className="absolute z-30 inset-0 block lg:size-[5.625rem] lg:fixed lg:top-base lg:left-7 lg:z-30 max:left-1/2 max:translate-x-[-43.25rem]">
                  <Logo
                    position="absolute"
                    className="inset-0 cursor-grab active:cursor-grabbing"
                    scrollMomentum
                  />
                </span>
                <Grid
                  width={90}
                  height={90}
                  className="absolute z-20 inset-0 lg:size-[5.625rem] lg:text-white lg:mix-blend-difference lg:fixed lg:left-7 lg:top-base max:left-1/2 max:translate-x-[-43.25rem]"
                />
              </div>
              <Link
                to={homeLink}
                aria-label={formatMessage({
                  id: "goto_homepage",
                  defaultMessage: "Go to homepage",
                })}
              >
                <LogoWordmark className="h-16 w-auto xs:h-20 flex-none lg:-my-[0.0625rem] lg:h-auto" />
              </Link>
            </div>
            <nav className="hidden lg:mr-base lg:block">
              <ul className="flex items-center space-x-md">
                {links?.map((item) => {
                  if (!item) return null;

                  const { id, ...document } = item;

                  return (
                    <li key={id} className="relative">
                      <DocumentLink
                        className="relative inline-block py-3f"
                        activeClassName="after:absolute after:inset-x-0 after:bottom-0 after:border-b-1f after:border-current"
                        partiallyActive
                        {...document}
                      />
                      {document.reference?.id === tendersPageId && (
                        <>
                          <span
                            aria-hidden="true"
                            className="absolute block -mt-3.5 -ml-0.5 rounded-full bg-theme-100/40 left-full top-1/2 size-3 animate-ping"
                          />
                          <span
                            title={formatMessage({
                              id: "open_tenders",
                              defaultMessage: "Open Tenders",
                            })}
                            aria-hidden="true"
                            className="absolute block -mt-3 bg-theme-100 rounded-full left-full top-1/2 size-2 z-[1]"
                          />
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </nav>
            <LocaleSwitcher className="hidden md:block" />
            <ThemeSwitcher id="theme-switcher" className="hidden md:block" />
            <div className="flex h-16 shrink-0 items-center justify-center text-black xs:h-20 lg:mix-blend-difference lg:text-white z-50 lg:fixed lg:right-7 lg:size-[5.625rem] max:left-1/2 max:translate-x-[43.25rem] max:ml-[-5.625rem]">
              <MegaMenuButton id="mega-menu" />
            </div>
          </Container>
          <MegaMenu id="mega-menu" />
        </header>
      )}
    </ThemeContext.Consumer>
  );
};

export default Header;
