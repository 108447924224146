import React, { useEffect, useMemo } from "react";
import { m as motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import ReactFocusLock from "react-focus-lock";
import { useIntl } from "react-intl";
import Container from "@/components/container";
import { documentLink } from "@/components/document-link";
import Link from "@/components/link";
import ThemeContext, { useTheme } from "@/contexts/theme";
import LocaleSwitcher from "@/layouts/partials/locale-switcher";
import MegaMenuNavigation from "@/layouts/partials/mega-menu-navigation";
import ThemeSwitcher from "@/layouts/partials/theme-switcher";

const MegaMenu: React.FC<{ id: string }> = ({ id }) => {
  const { locale } = useIntl();
  const { menuOpen, setMenuOpen } = useTheme();

  const { settings } = useStaticQuery<Queries.MegaMenuQuery>(graphql`
    query MegaMenu {
      settings: sanitySettings {
        megaNavigation {
          ...InternationalizedDocumentReferenceNestedList
        }
        megaNavigationFooter {
          ...InternationalizedDocumentReferenceList
        }
      }
    }
  `);

  const primaryNavigation = useMemo(
    () =>
      settings?.megaNavigation
        ?.find((item) => item?.locale === locale)
        ?.value?.filter((item) => !!item)
        .map((item) => {
          if ("children" in item!) {
            return {
              title: item.title!,
              links:
                item.children
                  ?.filter((child) => !!child)
                  ?.map((child) => documentLink(child!)) ?? [],
            };
          }

          return documentLink(item!);
        }),
    [settings, locale],
  );

  const secondaryNavigation = useMemo(
    () =>
      settings?.megaNavigationFooter
        ?.find((item) => item?.locale === locale)
        ?.value?.filter((item) => !!item)
        ?.map((item) => documentLink(item!)),
    [settings, locale],
  );

  useEffect(() => {
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape" && menuOpen) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("keyup", closeOnEscape, false);

    return () => {
      document.removeEventListener("keyup", closeOnEscape, false);
    };
  }, [menuOpen, setMenuOpen]);

  return (
    <ThemeContext.Consumer>
      {({ menuOpen, setMenuOpen }) => (
        <motion.div
          id={`megamenu-${id}`}
          aria-labelledby={`label-${id}`}
          className="fixed inset-x-0 top-0 z-40 flex flex-col overflow-hidden bg-gradient-to-b from-theme-200/80 to-theme-300/80 backdrop-blur-2xl py-md lg:py-base"
          initial="hidden"
          animate={menuOpen ? "visible" : "hidden"}
          variants={{
            visible: { opacity: 1, height: "100%", display: "flex" },
            hidden: {
              opacity: 0,
              height: "0%",
              transitionEnd: { display: "none" },
            },
          }}
        >
          <ReactFocusLock
            disabled={!menuOpen}
            autoFocus={false}
            returnFocus
            className="flex flex-col h-full"
          >
            <Container className="flex flex-col justify-center flex-1 py-2xl text-2xl-fluid">
              <div className="relative h-full max:grid max:grid-cols-12 max:gap-gutter">
                <div className="flex flex-col justify-center h-full max:col-span-10 max:col-start-2">
                  {primaryNavigation && (
                    <MegaMenuNavigation items={primaryNavigation} />
                  )}
                </div>
              </div>
            </Container>
            <Container className="flex flex-col gap-base md:flex-row md:justify-between">
              {secondaryNavigation && (
                <ul className="flex flex-wrap items-center text-sm-fluid gap-x-base gap-y-sm">
                  {secondaryNavigation.map(({ title, url }) => (
                    <li key={title}>
                      <Link
                        to={url}
                        className="text-theme-900 hover:text-theme-950"
                        onClick={() => setMenuOpen(false)}
                      >
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              <div className="flex items-center gap-base">
                <LocaleSwitcher />
                <ThemeSwitcher
                  id="mm-theme-switcher"
                  className="!text-theme-900"
                />
              </div>
            </Container>
          </ReactFocusLock>
        </motion.div>
      )}
    </ThemeContext.Consumer>
  );
};

export default MegaMenu;
