import "@resources/styles/global.css";
import React from "react";
import { domAnimation, LazyMotion } from "framer-motion";
import { useTheme } from "@/contexts/theme";
import Footer from "@/layouts/partials/footer";
import Header from "@/layouts/partials/header";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { theme } = useTheme();

  return (
    <LazyMotion features={domAnimation}>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex flex-col items-stretch flex-1 overflow-hidden">
          {children}
        </main>
      </div>
      <Footer />
      <div
        className={`${theme === "warm" ? "bg-square-pattern-warm" : "bg-square-pattern"} bg-[length:44px] h-[15vh]`}
      />
    </LazyMotion>
  );
};

export default Layout;
