import React from "react";
import Icon, { IconProps } from "@/icons/base";

const Instagram: React.FC<IconProps> = ({ ...props }) => (
  <Icon {...props}>
    <path d="m.75 3.75c0-1.65685 1.34315-3 3-3h8.5c1.6569 0 3 1.34315 3 3v8.5c0 1.6569-1.3431 3-3 3h-8.5c-1.65685 0-3-1.3431-3-3z" />
    <path d="m12.5 4c0 .27614-.2239.5-.5.5s-.5-.22386-.5-.5.2239-.5.5-.5.5.22386.5.5z" />
    <path d="m11.25 9c0 1.7949-1.4551 3.25-3.25 3.25s-3.25-1.4551-3.25-3.25 1.4551-3.25 3.25-3.25 3.25 1.4551 3.25 3.25z" />
  </Icon>
);

export default Instagram;
