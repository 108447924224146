import React from "react";

export type IconProps = Omit<
  React.SVGAttributes<SVGElement>,
  "width" | "height" | "viewBox" | "xmlns"
> & {
  size?: number;
  strokeWidth?: number;
};

const Icon: React.FC<IconProps> = ({
  size = 16,
  strokeWidth = 1.5,
  children = undefined,
  ...props
}) => (
  <svg
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      strokeWidth={strokeWidth}
    >
      {children}
    </g>
  </svg>
);

export default Icon;
