export enum Color {
  Cold600 = 0x2b4541,
  Cold500 = 0x3a8e91,
  Cold400 = 0x43a4a7,
  Cold300 = 0x60b2b5,
  Cold200 = 0x97cfd1,
  Cold100 = 0xafdcde,
  Warm500 = 0x5a4a3d,
  Warm400 = 0x98582b,
  Warm300 = 0xc47434,
  Warm200 = 0xc16e23,
  Warm100 = 0xc58e6c,
}

export type Polyhedra = {
  vertex: Array<[number, number, number]>;
  edge: Array<[number, number]>;
  face: Array<number[]>;
  colors: Record<number | "fallback", Color>;
  backColors: Record<number | "fallback", Color>;
};

export const TruncatedIcosidodecahedron: Polyhedra = {
  vertex: [
    [0, 0, 1.008759],
    [0.2629922, 0, 0.973874],
    [-0.00462747, 0.2629515, 0.973874],
    [-0.2211363, -0.1423503, 0.973874],
    [0.2583647, 0.2629515, 0.9389886],
    [0.4673861, -0.1423503, 0.8825429],
    [-0.2303913, 0.3835526, 0.9041033],
    [-0.3159502, -0.372678, 0.8825429],
    [-0.4469001, -0.02174919, 0.9041033],
    [0.4581312, 0.3835526, 0.8127722],
    [0.5351104, -0.372678, 0.7696515],
    [0.6671526, -0.02174919, 0.7563265],
    [-0.3326926, 0.5786893, 0.7563265],
    [-0.4515276, 0.2412023, 0.8692179],
    [-0.541714, -0.2520769, 0.8127722],
    [-0.248226, -0.6030057, 0.7696515],
    [0.518368, 0.5786893, 0.6434351],
    [0.6625252, 0.2412023, 0.7214412],
    [0.7348768, -0.2520769, 0.6434351],
    [0.4402965, -0.6030057, 0.6783205],
    [-0.5538289, 0.436339, 0.7214412],
    [-0.2724557, 0.7738261, 0.5869894],
    [-0.6997536, -0.3618034, 0.6301101],
    [-0.04383203, -0.745356, 0.6783205],
    [-0.4062656, -0.7127322, 0.5869894],
    [0.722762, 0.436339, 0.552104],
    [0.4160667, 0.7738261, 0.4956583],
    [0.8398294, -0.3618034, 0.4258876],
    [0.2191601, -0.745356, 0.6434351],
    [0.5452491, -0.7127322, 0.460773],
    [-0.7147284, 0.4891254, 0.5172187],
    [-0.07268925, 0.8944272, 0.460773],
    [-0.4333553, 0.8266125, 0.3827669],
    [-0.8606531, -0.309017, 0.4258876],
    [-0.6320294, -0.5921311, 0.5172187],
    [-0.2018716, -0.8550825, 0.4956583],
    [0.8248546, 0.4891254, 0.3129962],
    [0.1903029, 0.8944272, 0.4258876],
    [0.5181594, 0.8266125, 0.2565505],
    [0.9419221, -0.309017, 0.1867798],
    [0.7450156, -0.5921311, 0.3345566],
    [0.3241127, -0.8550825, 0.4258876],
    [-0.8727679, 0.3793989, 0.3345566],
    [-0.6544916, 0.6842621, 0.3478816],
    [-0.2335888, 0.9472136, 0.2565505],
    [-0.7929289, -0.5393447, 0.3129962],
    [-0.9629544, -0.1138803, 0.2781109],
    [-0.096919, -0.9648091, 0.2781109],
    [0.9298072, 0.3793989, 0.09544872],
    [0.7225533, 0.6842621, 0.1652194],
    [0.2923956, 0.9472136, 0.1867798],
    [0.8471082, -0.5393447, 0.09544872],
    [1.002159, -0.1138803, 0.01744268],
    [0.1660732, -0.9648091, 0.2432255],
    [-0.8125311, 0.5745356, 0.1652194],
    [-0.9675818, 0.1490712, 0.2432255],
    [-0.1314961, 1, 0.01744268],
    [-0.8275059, -0.5745356, 0.05232804],
    [-0.9975315, -0.1490712, 0.01744268],
    [-0.1314961, -1, 0.01744268],
    [0.8275059, 0.5745356, -0.05232804],
    [0.9975315, 0.1490712, -0.01744268],
    [0.1314961, 1, -0.01744268],
    [0.8125311, -0.5745356, -0.1652194],
    [0.9675818, -0.1490712, -0.2432255],
    [0.1314961, -1, -0.01744268],
    [-0.8471082, 0.5393447, -0.09544872],
    [-1.002159, 0.1138803, -0.01744268],
    [-0.1660732, 0.9648091, -0.2432255],
    [-0.7225533, -0.6842621, -0.1652194],
    [-0.9298072, -0.3793989, -0.09544872],
    [-0.2923956, -0.9472136, -0.1867798],
    [0.7929289, 0.5393447, -0.3129962],
    [0.9629544, 0.1138803, -0.2781109],
    [0.096919, 0.9648091, -0.2781109],
    [0.6544916, -0.6842621, -0.3478816],
    [0.8727679, -0.3793989, -0.3345566],
    [0.2335888, -0.9472136, -0.2565505],
    [-0.7450156, 0.5921311, -0.3345566],
    [-0.9419221, 0.309017, -0.1867798],
    [-0.3241127, 0.8550825, -0.4258876],
    [-0.8248546, -0.4891254, -0.3129962],
    [-0.5181594, -0.8266125, -0.2565505],
    [-0.1903029, -0.8944272, -0.4258876],
    [0.6320294, 0.5921311, -0.5172187],
    [0.8606531, 0.309017, -0.4258876],
    [0.2018716, 0.8550825, -0.4956583],
    [0.7147284, -0.4891254, -0.5172187],
    [0.4333553, -0.8266125, -0.3827669],
    [0.07268925, -0.8944272, -0.460773],
    [-0.8398294, 0.3618034, -0.4258876],
    [-0.5452491, 0.7127322, -0.460773],
    [-0.2191601, 0.745356, -0.6434351],
    [-0.722762, -0.436339, -0.552104],
    [-0.4160667, -0.7738261, -0.4956583],
    [0.6997536, 0.3618034, -0.6301101],
    [0.4062656, 0.7127322, -0.5869894],
    [0.04383203, 0.745356, -0.6783205],
    [0.5538289, -0.436339, -0.7214412],
    [0.2724557, -0.7738261, -0.5869894],
    [-0.7348768, 0.2520769, -0.6434351],
    [-0.4402965, 0.6030057, -0.6783205],
    [-0.6625252, -0.2412023, -0.7214412],
    [-0.518368, -0.5786893, -0.6434351],
    [0.541714, 0.2520769, -0.8127722],
    [0.248226, 0.6030057, -0.7696515],
    [0.4515276, -0.2412023, -0.8692179],
    [0.3326926, -0.5786893, -0.7563265],
    [-0.6671526, 0.02174919, -0.7563265],
    [-0.5351104, 0.372678, -0.7696515],
    [-0.4581312, -0.3835526, -0.8127722],
    [0.4469001, 0.02174919, -0.9041033],
    [0.3159502, 0.372678, -0.8825429],
    [0.2303913, -0.3835526, -0.9041033],
    [-0.4673861, 0.1423503, -0.8825429],
    [-0.2583647, -0.2629515, -0.9389886],
    [0.2211363, 0.1423503, -0.973874],
    [0.00462747, -0.2629515, -0.973874],
    [-0.2629922, 0, -0.973874],
    [0, 0, -1.008759],
  ],
  edge: [
    [0, 1],
    [1, 4],
    [4, 2],
    [2, 0],
    [3, 8],
    [8, 14],
    [14, 7],
    [7, 3],
    [5, 10],
    [10, 18],
    [18, 11],
    [11, 5],
    [6, 12],
    [12, 20],
    [20, 13],
    [13, 6],
    [9, 17],
    [17, 25],
    [25, 16],
    [16, 9],
    [15, 24],
    [24, 35],
    [35, 23],
    [23, 15],
    [19, 28],
    [28, 41],
    [41, 29],
    [29, 19],
    [21, 31],
    [31, 44],
    [44, 32],
    [32, 21],
    [22, 33],
    [33, 45],
    [45, 34],
    [34, 22],
    [26, 38],
    [38, 50],
    [50, 37],
    [37, 26],
    [27, 40],
    [40, 51],
    [51, 39],
    [39, 27],
    [30, 43],
    [43, 54],
    [54, 42],
    [42, 30],
    [36, 48],
    [48, 60],
    [60, 49],
    [49, 36],
    [46, 55],
    [55, 67],
    [67, 58],
    [58, 46],
    [47, 59],
    [59, 65],
    [65, 53],
    [53, 47],
    [52, 64],
    [64, 73],
    [73, 61],
    [61, 52],
    [56, 62],
    [62, 74],
    [74, 68],
    [68, 56],
    [57, 70],
    [70, 81],
    [81, 69],
    [69, 57],
    [63, 75],
    [75, 87],
    [87, 76],
    [76, 63],
    [66, 78],
    [78, 90],
    [90, 79],
    [79, 66],
    [71, 82],
    [82, 94],
    [94, 83],
    [83, 71],
    [72, 85],
    [85, 95],
    [95, 84],
    [84, 72],
    [77, 89],
    [89, 99],
    [99, 88],
    [88, 77],
    [80, 92],
    [92, 101],
    [101, 91],
    [91, 80],
    [86, 96],
    [96, 105],
    [105, 97],
    [97, 86],
    [93, 102],
    [102, 110],
    [110, 103],
    [103, 93],
    [98, 107],
    [107, 113],
    [113, 106],
    [106, 98],
    [100, 109],
    [109, 114],
    [114, 108],
    [108, 100],
    [104, 111],
    [111, 116],
    [116, 112],
    [112, 104],
    [115, 118],
    [118, 119],
    [119, 117],
    [117, 115],
    [2, 6],
    [13, 8],
    [3, 0],
    [1, 5],
    [11, 17],
    [9, 4],
    [14, 22],
    [34, 24],
    [15, 7],
    [10, 19],
    [29, 40],
    [27, 18],
    [12, 21],
    [32, 43],
    [30, 20],
    [25, 36],
    [49, 38],
    [26, 16],
    [35, 47],
    [53, 41],
    [28, 23],
    [31, 37],
    [50, 62],
    [56, 44],
    [33, 46],
    [58, 70],
    [57, 45],
    [51, 63],
    [76, 64],
    [52, 39],
    [54, 66],
    [79, 67],
    [55, 42],
    [48, 61],
    [73, 85],
    [72, 60],
    [59, 71],
    [83, 89],
    [77, 65],
    [74, 86],
    [97, 92],
    [80, 68],
    [81, 93],
    [103, 94],
    [82, 69],
    [75, 88],
    [99, 107],
    [98, 87],
    [78, 91],
    [101, 109],
    [100, 90],
    [95, 104],
    [112, 105],
    [96, 84],
    [102, 108],
    [114, 118],
    [115, 110],
    [113, 117],
    [119, 116],
    [111, 106],
  ],
  face: [
    [0, 1, 4, 2],
    [3, 8, 14, 7],
    [5, 10, 18, 11],
    [6, 12, 20, 13],
    [9, 17, 25, 16],
    [15, 24, 35, 23],
    [19, 28, 41, 29],
    [21, 31, 44, 32],
    [22, 33, 45, 34],
    [26, 38, 50, 37],
    [27, 40, 51, 39],
    [30, 43, 54, 42],
    [36, 48, 60, 49],
    [46, 55, 67, 58],
    [47, 59, 65, 53],
    [52, 64, 73, 61],
    [56, 62, 74, 68],
    [57, 70, 81, 69],
    [63, 75, 87, 76],
    [66, 78, 90, 79],
    [71, 82, 94, 83],
    [72, 85, 95, 84],
    [77, 89, 99, 88],
    [80, 92, 101, 91],
    [86, 96, 105, 97],
    [93, 102, 110, 103],
    [98, 107, 113, 106],
    [100, 109, 114, 108],
    [104, 111, 116, 112],
    [115, 118, 119, 117],
    [0, 2, 6, 13, 8, 3],
    [1, 5, 11, 17, 9, 4],
    [7, 14, 22, 34, 24, 15],
    [10, 19, 29, 40, 27, 18],
    [12, 21, 32, 43, 30, 20],
    [16, 25, 36, 49, 38, 26],
    [23, 35, 47, 53, 41, 28],
    [31, 37, 50, 62, 56, 44],
    [33, 46, 58, 70, 57, 45],
    [39, 51, 63, 76, 64, 52],
    [42, 54, 66, 79, 67, 55],
    [48, 61, 73, 85, 72, 60],
    [59, 71, 83, 89, 77, 65],
    [68, 74, 86, 97, 92, 80],
    [69, 81, 93, 103, 94, 82],
    [75, 88, 99, 107, 98, 87],
    [78, 91, 101, 109, 100, 90],
    [84, 95, 104, 112, 105, 96],
    [102, 108, 114, 118, 115, 110],
    [106, 113, 117, 119, 116, 111],
    [0, 3, 7, 15, 23, 28, 19, 10, 5, 1],
    [2, 4, 9, 16, 26, 37, 31, 21, 12, 6],
    [8, 13, 20, 30, 42, 55, 46, 33, 22, 14],
    [11, 18, 27, 39, 52, 61, 48, 36, 25, 17],
    [24, 34, 45, 57, 69, 82, 71, 59, 47, 35],
    [29, 41, 53, 65, 77, 88, 75, 63, 51, 40],
    [32, 44, 56, 68, 80, 91, 78, 66, 54, 43],
    [38, 49, 60, 72, 84, 96, 86, 74, 62, 50],
    [58, 67, 79, 90, 100, 108, 102, 93, 81, 70],
    [64, 76, 87, 98, 106, 111, 104, 95, 85, 73],
    [83, 94, 103, 110, 115, 117, 113, 107, 99, 89],
    [92, 97, 105, 112, 116, 119, 118, 114, 109, 101],
  ],
  colors: {
    fallback: Color.Cold500,
    0: Color.Warm400,
    1: Color.Cold300,
    2: Color.Warm100,
    3: Color.Cold300,
    4: Color.Warm200,
    5: Color.Cold500,
    6: Color.Warm400,
    7: Color.Cold300,
    8: Color.Cold300,
    9: Color.Warm400,
    10: Color.Warm200,
    11: Color.Cold100,
    12: Color.Warm400,
    13: Color.Cold300,
    14: Color.Cold600,
    15: Color.Warm200,
    16: Color.Cold500,
    17: Color.Warm200,
    18: Color.Warm200,
    19: Color.Cold300,
    20: Color.Warm400,
    21: Color.Warm100,
    22: Color.Warm400,
    23: Color.Cold500,
    24: Color.Warm500,
    25: Color.Warm200,
    26: Color.Warm200,
    27: Color.Cold500,
    28: Color.Warm200,
    29: Color.Warm200,
    30: Color.Cold500,
    31: Color.Warm300,
    32: Color.Cold200,
    33: Color.Warm300,
    34: Color.Cold200,
    35: Color.Warm100,
    36: Color.Warm500,
    37: Color.Cold400,
    38: Color.Cold400,
    39: Color.Warm100,
    40: Color.Cold200,
    41: Color.Warm300,
    42: Color.Warm500,
    43: Color.Cold600,
    44: Color.Warm300,
    45: Color.Warm300,
    46: Color.Cold400,
    47: Color.Warm300,
    48: Color.Warm300,
    49: Color.Warm100,
  },
  backColors: {
    fallback: Color.Warm100,
  },
};
