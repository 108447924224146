import React from "react";
import Icon, { IconProps } from "@/icons/base";

const ChevronRight: React.FC<IconProps> = ({ ...props }) => (
  <Icon {...props}>
    <path d="m6.25 4.75 3.5 3.25-3.5 3.25" />
  </Icon>
);

export default ChevronRight;
