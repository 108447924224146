import React from "react";
import Icon, { IconProps } from "@/icons/base";

const LinkedIn: React.FC<IconProps> = ({ ...props }) => (
  <Icon {...props}>
    <path d="m.75 3.75c0-1.65685 1.34315-3 3-3h8.5c1.6569 0 3 1.34315 3 3v8.5c0 1.6569-1.3431 3-3 3h-8.5c-1.65685 0-3-1.3431-3-3z" />
    <path d="m6.75 12.25v-2.25c0-1.2426 1.0074-2.25 2.25-2.25s2.25 1.0074 2.25 2.25v2.25" />
    <path d="m6.75 7.75v4.5" />
    <path d="m3.75 7.75v4.5" />
    <path d="m3.75 4.75v.5" />
  </Icon>
);

export default LinkedIn;
