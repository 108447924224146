import React from "react";
import Icon, { IconProps } from "@/icons/base";

const ArrowUp: React.FC<IconProps> = ({ ...props }) => (
  <Icon {...props}>
    <path d="m2.75 6.25 5.25-5.5 5.25 5.5" />
    <path d="m8 1v14.25" />
  </Icon>
);

export default ArrowUp;
