import React from "react";
import Icon, { IconProps } from "@/icons/base";

const Sun: React.FC<IconProps> = ({ ...props }) => (
  <Icon {...props}>
    <path d="m10.24977 8c0 1.2426-1.0074 2.25-2.25 2.25s-2.25-1.0074-2.25-2.25c0-1.24264 1.0074-2.25 2.25-2.25s2.25 1.00736 2.25 2.25z" />
    <path d="m8 2.75v.5" />
    <path d="m8 12.75v.5" />
    <path d="m13.25 8h-.5" />
    <path d="m3.25 8h-.5" />
    <path
      d="m11.535 4.214v.5"
      transform="matrix(.70710678 .70710678 -.70710678 .70710678 6.535755 -6.848708)"
    />
    <path
      d="m4.464 11.286v.5"
      transform="matrix(.70710678 .70710678 -.70710678 .70710678 9.463952 .221999)"
    />
    <path
      d="m11.785 11.536h-.5"
      transform="matrix(.70710678 .70710678 -.70710678 .70710678 11.535 -4.777953)"
    />
    <path
      d="m4.714 4.464h-.5"
      transform="matrix(.70710678 .70710678 -.70710678 .70710678 4.464707 -1.848756)"
    />
  </Icon>
);

export default Sun;
