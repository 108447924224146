exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-participate-tsx": () => import("./../../../src/pages/participate.tsx" /* webpackChunkName: "component---src-pages-participate-tsx" */),
  "component---src-templates-lab-tsx": () => import("./../../../src/templates/lab.tsx" /* webpackChunkName: "component---src-templates-lab-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-category-tsx": () => import("./../../../src/templates/post-category.tsx" /* webpackChunkName: "component---src-templates-post-category-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-tender-tsx": () => import("./../../../src/templates/tender.tsx" /* webpackChunkName: "component---src-templates-tender-tsx" */)
}

