import React from "react";
import Icon, { IconProps } from "@/icons/base";

const SunBase: React.FC<IconProps> = ({ ...props }) => (
  <Icon {...props}>
    <path d="m10.24977 8c0 1.2426-1.0074 2.25-2.25 2.25s-2.25-1.0074-2.25-2.25c0-1.24264 1.0074-2.25 2.25-2.25s2.25 1.00736 2.25 2.25z" />
  </Icon>
);

export default SunBase;
