import * as React from "react";
import type { LinkProps } from "@components/link";
import Link from "@components/link";
import { slug } from "@/utils/slug";

export type DocumentLinkFnProps = {
  title?: string | null;
  reference?:
    | ({
        title: string | null;
      } & Translatable)
    | null;
} & Translatable;

export interface DocumentLinkProps
  extends Omit<LinkProps, "title" | "to">,
    DocumentLinkFnProps {}

export const documentLink = ({
  title,
  reference,
  slug: slugProp,
  language,
}: DocumentLinkFnProps): { url: string; title: string } => ({
  title: title ?? reference?.title ?? "",
  url: slug(slugProp ?? reference?.slug, language ?? reference?.language),
});

const DocumentLink: React.FC<DocumentLinkProps> = ({
  title: titleProp = undefined,
  reference = undefined,
  slug: slugProp,
  language,
  children = undefined,
  ...props
}) => {
  const { title, url } = documentLink({
    title: titleProp,
    reference,
    slug: slugProp,
    language,
  });

  return (
    <Link to={url} {...props}>
      {children ?? title}
    </Link>
  );
};

export default DocumentLink;
