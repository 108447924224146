import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "@/contexts/theme";
import { cn } from "@/helpers/classNames";
import OnIcon from "@/icons/sun";
import OffIcon from "@/icons/sun-base";

const ThemeSwitcher: React.FC<{ id: string; className?: string }> = ({
  id,
  className = undefined,
}) => {
  const { theme, setTheme } = useTheme();
  const [focused, setFocused] = React.useState(false);

  const { formatMessage } = useIntl();

  const checked = useMemo(() => theme === "warm", [theme]);

  const handleChange = (checked: boolean) =>
    setTheme(checked ? "warm" : "cold");

  return (
    <div className={cn(className, "flex items-center text-theme-800/75")}>
      <button
        type="button"
        title={formatMessage({
          id: "choose_theme_cold",
          defaultMessage: "Choose cold color scheme",
        })}
        aria-label={formatMessage({
          id: "choose_theme_cold",
          defaultMessage: "Choose cold color scheme",
        })}
        onClick={() => setTheme("cold")}
        tabIndex={-1}
      >
        <OffIcon />
      </button>
      <label
        htmlFor={id}
        className={`leading-none relative inline-block transition rounded-full cursor-pointer select-none focus:outline-none ${
          focused ? "ring-4 ring-highlight" : ""
        }`}
      >
        <input
          role="switch"
          aria-checked={checked}
          checked={checked}
          className="sr-only"
          id={id}
          onChange={(e) => handleChange(e.target.checked)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          type="checkbox"
        />
        <div
          className="h-4 rounded-full w-7 bg-theme-700"
          role="presentation"
        />
        <div
          className={`absolute top-[0.1875rem] left-[0.1875rem] w-2.5 h-2.5 bg-white rounded-full shadow-md transition duration-300 transform-gpu ${
            checked ? "translate-x-3" : "translate-x-0"
          }`}
          role="presentation"
        />
        <span className="sr-only">
          <FormattedMessage
            id="switch_theme"
            defaultMessage="Switch color scheme"
          />{" "}
        </span>
      </label>
      <button
        type="button"
        title={formatMessage({
          id: "choose_theme_warm",
          defaultMessage: "Choose warm color scheme",
        })}
        aria-label={formatMessage({
          id: "choose_theme_warm",
          defaultMessage: "Choose warm color scheme",
        })}
        onClick={() => setTheme("warm")}
        tabIndex={-1}
      >
        <OnIcon />
      </button>
    </div>
  );
};

export default ThemeSwitcher;
