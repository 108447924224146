import React, { useMemo } from "react";

const Grid: React.FC<
  {
    width: number;
    height: number;
    gridSize?: number;
    gridX?: number;
    gridY?: number;
  } & Omit<React.HTMLAttributes<SVGElement>, "width" | "height">
> = ({ width, height, gridSize = 3, gridX = 5, gridY = 6, ...props }) => {
  const grid = useMemo<Array<{ x: number; y: number }>>(
    () =>
      Array(gridX * gridY)
        .fill(0)
        .map((_, i) => ({
          x:
            ((width - gridSize * gridX) / (gridX - 1) + gridSize) * (i % gridX),
          y:
            ((height - gridSize * gridY) / (gridY - 1) + gridSize) *
            (i % gridY),
        })),
    [gridSize, gridX, gridY, height, width],
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        {grid.map(({ x, y }) => (
          <rect
            key={`${x}_${y}`}
            x={x ? x.toFixed(1) : undefined}
            y={y ? y.toFixed(1) : undefined}
            width={gridSize}
            height={gridSize}
          />
        ))}
      </g>
    </svg>
  );
};

export default Grid;
