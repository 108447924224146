import React from "react";
import Icon, { IconProps } from "@/icons/base";

const ArrowLeft: React.FC<IconProps> = ({ ...props }) => (
  <Icon {...props}>
    <path d="m6.25 2.75-5.5 5.25 5.5 5.25" />
    <path d="m1 8h14.25" />
  </Icon>
);

export default ArrowLeft;
