import React from "react";
import { useTheme } from "@/contexts/theme";

const Theme: React.FC = () => {
  const { theme } = useTheme();

  if (theme === "cold")
    return (
      <style>
        {`
      :root {
        --color-highlight: 182, 80%, 40%;
        --color-theme-50: 182, 30%, 96%;
        --color-theme-100: 182, 42%, 89%;
        --color-theme-200: 182, 39%, 73%;
        --color-theme-300: 181, 34%, 66%;
        --color-theme-400: 181, 37%, 60%;
        --color-theme-500: 182, 36%, 55%;
        --color-theme-600: 182, 43%, 46%;
        --color-theme-700: 182, 43%, 40%;
        --color-theme-800: 178, 33%, 27%;
        --color-theme-900: 178, 27%, 22%;
        --color-theme-950: 178, 30%, 14%;
      }
    `}
      </style>
    );

  return (
    <style>
      {`
          :root {
            --color-highlight: 23, 75%, 55%;
            --color-theme-50: 23, 43%, 96%;
            --color-theme-100: 23, 48%, 80%;
            --color-theme-200: 23, 44%, 68%;
            --color-theme-300: 23, 33%, 60%;
            --color-theme-400: 23, 36%, 56%;
            --color-theme-500: 23, 31%, 52%;
            --color-theme-600: 27, 26%, 48%;
            --color-theme-700: 28, 28%, 42%;
            --color-theme-800: 25, 37%, 38%;
            --color-theme-900: 28, 27%, 29%;
            --color-theme-950: 28, 16%, 19%;
          }
        `}
    </style>
  );
};

export default Theme;
