import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface ThemeContextProps {
  theme: Theme;
  setTheme: (theme?: Theme) => void;
  switchTheme: () => void;
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  toggleMenu: () => void;
  activeSubmenu: number | string | null;
  setActiveSubmenu: (index: number | string | null) => void;
  headerClassName: string;
  setHeaderClassName: (className: string) => void;
  resetHeaderClassName: () => void;
}

const themeContextDefaults: {
  theme: Theme;
  menuOpen: boolean;
  activeSubmenu: null;
  headerClassName: string;
} = {
  theme: "cold",
  menuOpen: false,
  activeSubmenu: null,
  headerClassName: "bg-theme-200",
};

const ThemeContext = React.createContext<ThemeContextProps>(
  themeContextDefaults as ThemeContextProps,
);

const ThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [theme, setThemeState] = useState<Theme>(themeContextDefaults.theme);

  const [headerClassName, setHeaderClassName] = useState<string>(
    themeContextDefaults.headerClassName,
  );

  const [menuOpen, setMenuOpenState] = useState<boolean>(
    themeContextDefaults.menuOpen,
  );

  const [activeSubmenu, setActiveSubmenu] = useState<number | string | null>(
    null,
  );

  const setMenuOpen = useCallback(
    (open: boolean) => {
      setMenuOpenState(open);

      if (!open) {
        setActiveSubmenu(null);
      }
    },
    [setMenuOpenState],
  );

  const setTheme = useCallback(
    (theme?: Theme) => {
      setThemeState(theme ?? (theme === "cold" ? "warm" : "cold"));

      if (theme === themeContextDefaults.theme) {
        window?.localStorage?.removeItem("theme");
      } else {
        window?.localStorage?.setItem("theme", JSON.stringify(theme));
      }
    },
    [setThemeState],
  );

  const switchTheme = useCallback(() => setTheme(), [setTheme]);

  const toggleMenu = useCallback(
    () => setMenuOpen(!menuOpen),
    [setMenuOpen, menuOpen],
  );

  const resetHeaderClassName = useCallback(
    () => setHeaderClassName(themeContextDefaults.headerClassName),
    [setHeaderClassName],
  );

  useEffect(() => {
    if (!document.head.querySelector("meta[name='theme-color']")) {
      const meta = document.createElement("meta");

      meta.setAttribute("name", "theme-color");

      document.head.appendChild(meta);
    }

    document.head
      .querySelector("meta[name='theme-color']")
      ?.setAttribute("content", theme === "warm" ? "#d1a489" : "#9fd3d5");

    return () =>
      document.head.querySelector("meta[name='theme-color']")?.remove();
  }, [theme]);

  useEffect(() => {
    const storedTheme = window?.localStorage?.getItem("theme");

    if (storedTheme) {
      setTheme(JSON.parse(storedTheme) as Theme);
    }
  }, [setTheme]);

  const value = useMemo(
    () => ({
      theme,
      switchTheme,
      setTheme,
      menuOpen,
      setMenuOpen,
      toggleMenu,
      activeSubmenu,
      setActiveSubmenu,
      headerClassName,
      setHeaderClassName,
      resetHeaderClassName,
    }),
    [
      theme,
      switchTheme,
      setTheme,
      menuOpen,
      setMenuOpen,
      toggleMenu,
      activeSubmenu,
      setActiveSubmenu,
      headerClassName,
      setHeaderClassName,
      resetHeaderClassName,
    ],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeContext;

export { ThemeProvider };

export const useTheme = () => useContext(ThemeContext);
