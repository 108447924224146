import { findLocale } from "../locales/locales";

export const slug = (
  slug: string | { current?: string | null } | null | undefined,
  locale?: Locale | null | string,
): string => {
  const parts = [typeof slug === "string" ? slug : (slug?.current ?? "")];

  if (parts[0] === "home") {
    delete parts[0];
  }

  if (locale && !locale.includes("de")) {
    const localeSettings = findLocale(locale);

    if (localeSettings) {
      parts.unshift(localeSettings.prefix);
    }
  }

  return `/${parts.join("/")}`;
};
