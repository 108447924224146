export const locales: Array<Omit<LocaleLink, "isCurrent" | "isDefault">> = [
  {
    locale: "de",
    prefix: "",
    name: "Deutsch",
    abbr: "DE",
    url: "/",
  },
  {
    locale: "en",
    prefix: "en",
    name: "English",
    abbr: "EN",
    url: "/en",
  },
];

export const findLocale = (locale: string | null | undefined) => {
  if (!locale) {
    return null;
  }

  if (locale.length === 5) {
    return locales.find((l) => l.locale === locale);
  }

  return locales.find((l) => l.locale.indexOf(locale) !== -1);
};
